import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./help.css";
import check1 from "./HelpImgs/check1.png";

const HelpChecks = () => {
  return (
    <Container className="help-container">
      <Row>
        <Col md={12}>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h1 className="help-title">Checks</h1>
              <Row className="align-items-center">
                <Col md={12}>
                  <p className="help-paragraph">Each check has:</p>
                  <ul className="help-list">
                    <li className="help-list-item">
                      A long title - IEP Date Range Not One Year Minus One Day
                      (this title can differ from the title in the navigation
                      menu).
                    </li>
                    <li className="help-list-item">
                      More info button - click to read more about how the check
                      works.
                    </li>
                    <li className="help-list-item">
                      Export button - click to export the data contained in the
                      check.
                    </li>
                    <li className="help-list-item">
                      Filter section to find individual students or staff.
                    </li>
                    <li className="help-list-item">Print button.</li>
                    <li className="help-list-item">
                      Count of records on the check.
                    </li>
                  </ul>
                </Col>
                <Col md={7}>
                  {/* Placeholder for images or additional content if needed */}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={12}>
                  <p className="help-paragraph">Each column has:</p>
                  <ul className="help-list">
                    <li className="help-list-item">
                      A 2-letter code indicating which EMIS file is used.
                    </li>
                    <li className="help-list-item">Name of the column.</li>
                    <li className="help-list-item">
                      Columns without the 2-letter code are usually a count
                      being done for the check - like "Difference" in this check
                      - a number is provided.
                    </li>
                    <li className="help-list-item">
                      Review column that allows the user to change the warning
                      icon to green and vice versa.
                    </li>
                    <li className="help-list-item">
                      Comment box next to the review column.
                    </li>
                    <li className="help-list-item">
                      Columns are sortable - click on any column name to sort in
                      ascending or descending order. An icon indicates which
                      column is sorted and the order (A/Z or Z/A).
                    </li>
                  </ul>
                </Col>
                <Col md={7}>
                  {/* Placeholder for images or additional content if needed */}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={3}>
                  <p className="help-paragraph" style={{ fontWeight: "bold" }}>
                    Each check has a legend at the bottom:
                  </p>
                </Col>
                <Col md={9} className="text-center">
                  <Card.Img
                    className="help-img-fluid"
                    src={check1}
                    alt="Check legend"
                    style={{ width: "90%" }}
                  />
                </Col>
              </Row>
              <Row className="align-items-center mt-3">
                <Col md={12}>
                  <ul className="help-list">
                    <li className="help-list-item">
                      The legend indicates which records are being used and a
                      timestamp of when the most recent data was collected.
                    </li>
                    <li className="help-list-item">
                      The example above shows that the GE was collected on
                      3/14/23 and the GE_Last that is being used was collected
                      on 7/12/2022.
                    </li>
                    <li className="help-list-item red">
                      *Please note – If a required file type has not been
                      prepared in the data collector, the check will not run and
                      will produce an ‘err’ message next to the check.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={12}>
                  <p className="help-paragraph">
                    Reviews are user-driven. Here is what we recommend:
                  </p>
                </Col>
                <Col md={12}>
                  <ol className="help-list">
                    <li className="help-list-item">
                      If the record will never be cleared, click the yellow icon
                      to turn it green AND enter a brief comment as to why it
                      will never clear. Green-reviewed records drop to the
                      bottom.
                    </li>
                    <li className="help-list-item">
                      If the record should be cleared with a subsequent
                      submission, then provide a comment and leave it yellow.
                    </li>
                    <li className="help-list-item">
                      Don't write a book, but do provide enough information to
                      inform others of what happened.
                    </li>
                  </ol>
                </Col>
              </Row>
              <Row className="align-items-center mt-3">
                <Col md={12}>
                  <p className="help-paragraph">
                    Example: ETR from 2/20/2020 never reported to ODE.
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HelpChecks;
