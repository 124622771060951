import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, Link, NavLink, useNavigate } from "react-router-dom";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import ReportBadge from "../ReportBadge/ReportBadge";
import { Nav, Spinner, InputGroup, FormControl } from "react-bootstrap";
import "./SideNavigation.css";
import { sortBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faRefresh,
  faFileImport,
  faUser,
  faHome,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(
  faChevronRight,
  faChevronLeft,
  faRefresh,
  faFileImport,
  faUser,
  fas
);

const SideNavigation = () => {
  const {
    menu,
    toReview,
    treeToReview,
    hasReview,
    reportData,
    refreshData,
    crosscheckAdmin,
    itcSifAdministrator,
  } = useContext(SyncReportsContext);
  const [expandedMenuId, setExpandedMenuId] = useState(null);
  const [sideNavOpen, setSideNavOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const excludedMenuTitles = [
    "Import Data",
    "Refresh Reports",
    "Sign Off",
    "Toggle Expand",
    "User Management",
    "Welcome",
    "crosscheck",
  ];

  const navigate = useNavigate();
  const menuRef = useRef(null);
  const mainContentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mainContentRef.current &&
        mainContentRef.current.contains(event.target)
      ) {
        setExpandedMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDashboardClick = (menuItem) => {
    // Ensure reports is an array
    const hasData =
      Array.isArray(menuItem.reports) &&
      menuItem.reports.some(
        (report) =>
          reportData[report.report_id] &&
          !reportData[report.report_id].error &&
          reportData[report.report_id].data
      );

    if (!hasData) {
      return; // Do nothing if no reports have data
    }

    const reportIds = menuItem.reports.map((report) => report.report_id);

    if (reportIds.some((reportId) => isReportLoading(reportId))) {
      return;
    }

    navigate(`/dashboard/${menuItem.menu_id}`, {
      state: {
        reportIds,
        dashboardTitle: menuItem.title,
        dashboardNotes: menuItem.notes,
        reportMoreInfo: menuItem.reports.map((report) => report.more_info),
        source: menuItem.reports.map((report) => report.source),
        dataset: menuItem.reports.map((report) => report.dataset),
        dataSources: menuItem.reports.map((report) => report.dataSources),
      },
    });
  };

  const handleMenuClick = (menuItem) => {
    const path =
      menuItem.menu_id === 8
        ? `/mergedFiles/${menuItem.report_id}`
        : `/datatable/${menuItem.report_id}`;

    navigate(path, {
      state: {
        reportTitle: menuItem.menu_title,
        reportMoreInfo: menuItem.more_info,
        source: menuItem.source,
        dataset: menuItem.dataset,
        dataSources: menuItem.dataSources,
        reportComment: menuItem.comment || null,
        reviewed: menuItem.reviewed,
      },
    });
  };

  const isReportLoading = (reportId) => {
    return reportData[reportId]?.loading;
  };

  const toggleMenu = (menuId) => {
    setExpandedMenuId((prevId) => (prevId === menuId ? null : menuId));
  };

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  const sideNavColClass = sideNavOpen ? "col-2" : "col-1";
  const mainContentColClass = sideNavOpen ? "col-10" : "col-11";

  const handleClearSearch = () => {
    setSearchTerm("");
    document.getElementById("search-input").value = "";
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredMenu = menu
    .filter(
      (menuItem) =>
        !menuItem.deleted &&
        menuItem.ad_only !== true &&
        menuItem.reports?.length > 0
    )
    .map((menuItem) => {
      const filteredReports = menuItem.reports.filter((report) => {
        //Show for crosscheck admins and itcSifAdministrator
        const isVisibleToUser =
          crosscheckAdmin || itcSifAdministrator || report.publish === 1;
        return (
          isVisibleToUser &&
          (report.title.toLowerCase().includes(searchTerm) ||
            menuItem.title.toLowerCase().includes(searchTerm))
        );
      });

      // Only return menu items that either match the search or have matching reports
      return filteredReports.length > 0 ||
        menuItem.title.toLowerCase().includes(searchTerm)
        ? { ...menuItem, reports: filteredReports }
        : null;
    })
    .filter((menuItem) => menuItem !== null);

  const sortReports = (reports, sortByField) =>
    sortBy(reports, (report) => report[sortByField]);

  const handleWelcomeClick = () => {
    navigate("/");
    refreshData();
  };

  const isReportDisabled = (reportId) => {
    return reportData[reportId] && reportData[reportId].error;
  };

  return (
    <>
      <div className={sideNavColClass}>
        <Nav
          id="navContainer"
          className={`flex-column ${!sideNavOpen ? "side-nav-closed" : ""}`}
          ref={menuRef}
        >
          <div className="togglerIcon" onClick={toggleSideNav}>
            <FontAwesomeIcon
              icon={sideNavOpen ? faChevronLeft : faChevronRight}
              className="folderIcon"
              style={{ color: "white" }}
            />
          </div>

          <ul className="nav-ul">
            <InputGroup className="mb-3-reports">
              <InputGroup.Text id="search-addon">
                <FontAwesomeIcon icon={faSearch} className="reportSearchBtn" />
              </InputGroup.Text>
              <FormControl
                id="search-input"
                placeholder="Search reports..."
                aria-label="Search reports"
                aria-describedby="search-addon"
                onChange={handleSearchChange}
              />
              {searchTerm && (
                <InputGroup.Text
                  id="clear-search-addon"
                  onClick={handleClearSearch}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </InputGroup.Text>
              )}
            </InputGroup>

            {filteredMenu.map(
              (menuItem, index) =>
                !excludedMenuTitles.includes(menuItem.title) && (
                  <li key={index}>
                    {menuItem.menu_type === "dashboard" ? (
                      <div
                        onClick={() => handleDashboardClick(menuItem)}
                        style={{
                          cursor: "pointer",
                          pointerEvents:
                            Array.isArray(menuItem.reports) &&
                            menuItem.reports.some(
                              (report) =>
                                reportData[report.report_id] &&
                                !reportData[report.report_id].error &&
                                reportData[report.report_id].data
                            )
                              ? "auto"
                              : "none",
                          opacity:
                            Array.isArray(menuItem.reports) &&
                            menuItem.reports.some(
                              (report) =>
                                reportData[report.report_id] &&
                                !reportData[report.report_id].error &&
                                reportData[report.report_id].data
                            )
                              ? 1
                              : 0.5,
                        }}
                      >
                        <label>
                          <FontAwesomeIcon icon={menuItem.icon_active} />
                          {menuItem.title}
                          {Array.isArray(menuItem.reports) &&
                            menuItem.reports.some((report) =>
                              isReportLoading(report.report_id)
                            ) && (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                                className="dashboardSpinner"
                              />
                            )}
                        </label>
                      </div>
                    ) : (
                      <>
                        <div
                          onClick={() => toggleMenu(menuItem.menu_id)}
                          className={
                            expandedMenuId === menuItem.menu_id
                              ? "selected"
                              : ""
                          }
                        >
                          <FontAwesomeIcon
                            icon={menuItem.icon_active || menuItem.icon}
                          />
                          <label>{menuItem.title}</label>
                          {treeToReview(menuItem) !== 0 &&
                            menuItem.menu_id !== 8 && (
                              <ReportBadge
                                className="badge badge-pill badge-danger menuTotal"
                                bg="danger"
                                message={treeToReview(menuItem)}
                              />
                            )}
                        </div>

                        {expandedMenuId === menuItem.menu_id && (
                          <ul className="navDropMenu expanded">
                            {menuItem.menu_type === "notes"
                              ? sortReports(
                                  menuItem.reports || [],
                                  "sort_by"
                                ).map((report) => (
                                  <li
                                    key={report.report_id}
                                    className={
                                      report.publish !== 1 ? "unpublished" : ""
                                    }
                                  >
                                    <NavLink
                                      to={`/releasenotes/${report.report_id}`}
                                      data-target={`#${report.title.replace(
                                        / /g,
                                        ""
                                      )}`}
                                      onClick={(e) => {
                                        if (
                                          menuItem.menu_type === "notes" &&
                                          !isReportDisabled(report.report_id)
                                        ) {
                                          // Allow navigation for release notes
                                        } else {
                                          e.preventDefault();
                                          handleMenuClick(report);
                                        }
                                      }}
                                      className={
                                        isReportDisabled(report.report_id) ||
                                        isReportLoading(report.report_id)
                                          ? "disabled-link"
                                          : ""
                                      }
                                      style={{
                                        pointerEvents:
                                          isReportDisabled(report.report_id) ||
                                          isReportLoading(report.report_id)
                                            ? "none"
                                            : "auto",
                                      }}
                                    >
                                      <FontAwesomeIcon icon={report.icon} />
                                      {report.menu_title}
                                    </NavLink>
                                  </li>
                                ))
                              : sortReports(
                                  menuItem.reports || [],
                                  "menu_title"
                                ).map((report) => (
                                  <li
                                    key={report.report_id}
                                    className={
                                      report.publish !== 1 ? "unpublished" : ""
                                    }
                                  >
                                    <NavLink
                                      to={`/report/${report.report_id}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleMenuClick(report);
                                      }}
                                      className={
                                        isReportDisabled(report.report_id) ||
                                        isReportLoading(report.report_id)
                                          ? "disabled-link"
                                          : ""
                                      }
                                      style={{
                                        pointerEvents:
                                          isReportDisabled(report.report_id) ||
                                          isReportLoading(report.report_id)
                                            ? "none"
                                            : "auto",
                                      }}
                                    >
                                      <FontAwesomeIcon icon={report.icon} />
                                      {report.menu_title}
                                      {reportData[report.report_id]
                                        ?.loading && (
                                        <Spinner
                                          animation="border"
                                          variant="primary"
                                          size="sm"
                                          className="reportSpinner"
                                        />
                                      )}
                                      {reportData[report.report_id] &&
                                        !reportData[report.report_id]
                                          .loading && (
                                          <>
                                            {(() => {
                                              const data =
                                                reportData[report.report_id];
                                              const hasReviewMessage =
                                                hasReview(data);
                                              const toReviewMessage =
                                                toReview(data);

                                              let badges = [];

                                              if (data.error) {
                                                badges.push(
                                                  <ReportBadge
                                                    key="err"
                                                    className="report-badge"
                                                    bg="warning"
                                                    message="err"
                                                  />
                                                );
                                              }

                                              if (hasReviewMessage > 0) {
                                                badges.push(
                                                  <ReportBadge
                                                    key="review"
                                                    className="report-badge"
                                                    bg="success"
                                                    message={hasReviewMessage.toString()}
                                                  />
                                                );
                                              }

                                              if (toReviewMessage > 500) {
                                                badges.push(
                                                  <ReportBadge
                                                    key="warn"
                                                    className="report-badge"
                                                    bg={
                                                      menuItem.menu_id === 8
                                                        ? "primary"
                                                        : "danger"
                                                    }
                                                    message={
                                                      menuItem.menu_id === 8
                                                        ? toReviewMessage.toString()
                                                        : "500+"
                                                    }
                                                  />
                                                );
                                              }

                                              if (
                                                toReviewMessage > 0 &&
                                                toReviewMessage <= 500
                                              ) {
                                                badges.push(
                                                  <ReportBadge
                                                    key="warn"
                                                    className="report-badge"
                                                    bg={
                                                      menuItem.menu_id === 8
                                                        ? "primary"
                                                        : "danger"
                                                    }
                                                    message={toReviewMessage.toString()}
                                                  />
                                                );
                                              }

                                              return badges;
                                            })()}
                                          </>
                                        )}
                                    </NavLink>
                                  </li>
                                ))}
                          </ul>
                        )}
                      </>
                    )}
                  </li>
                )
            )}

            <li>
              <div onClick={handleWelcomeClick} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faRefresh} />
                <label>Refresh Reports</label>
              </div>
            </li>

            <li>
              <Link to="importdata">
                <FontAwesomeIcon icon={faFileImport} />
                <label>Import Data</label>
              </Link>
            </li>
            <li>
              <Link to="usermanagement">
                <FontAwesomeIcon icon={faUser} />
                <label>User Management</label>
              </Link>
            </li>
            <li>
              <Link to="/">
                <FontAwesomeIcon icon={faHome} />
                <label>Welcome</label>
              </Link>
            </li>
            <li>
              <Link to="logout">
                <FontAwesomeIcon icon={faUser} />
                <label>Sign Off</label>
              </Link>
            </li>
          </ul>
        </Nav>
      </div>

      <div
        className={`main-content-container ${mainContentColClass}`}
        ref={mainContentRef}
      >
        <Outlet />
      </div>
    </>
  );
};

export default SideNavigation;
