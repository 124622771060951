import React, { useEffect, useState } from "react";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faCheck, faUpload } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Modal,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./ImportData.css";

const ImportData = () => {
  const [file, setFile] = useState(null);
  const [importLogError, setImportLogError] = useState("");
  const [loading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { selectedDistrict } = useDistrict();

  const fetchLogs = async () => {
    try {
      const response = await axios.get(
        `/cc/transaction/district/${selectedDistrict.irn}`,
        {
          headers: {
            Authorization: `Bearer YOUR_TOKEN_HERE`,
          },
        }
      );

      if (response.data.error) {
        setImportLogError(response.data.error);
      } else {
        let logs = response.data.map((log) => ({
          ...log,
          transaction_log_date: new Date(
            log.transaction_log_date.substring(0, 25)
          ),
        }));

        logs = logs.sort(
          (a, b) => b.transaction_log_date - a.transaction_log_date
        );
        logs = logs.slice(0, 120);
        setLogsData(logs);
      }
    } catch (error) {
      setImportLogError("Failed to fetch logs.");
    } 
  };

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistrict]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const uploadData = async () => {
    const formData = new FormData();
    formData.append("fileToUpload", file);

    setLoading(true);

    try {
      const response = await axios.post(
        `/cc/import/${selectedDistrict.irn}`,
        formData,
        {
          headers: {
            Authorization: `Bearer YOUR_TOKEN_HERE`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setModalMessage("Successfully imported data!");
        fetchLogs(); // Re-fetch logs when import is successful
      } else {
        setModalMessage(response.data.message);
      }
    } catch (error) {
      setModalMessage(error.response?.data?.message || "Upload Failed");
    } finally {
      setLoading(false);
      setShowModal(true);
    }
  };
  
  return (
    <Container className="import-data-container">
      <Row>
        <Col>
          <h3 className="my-4">Import Data</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Importing Files to CrossCheck</h5>
          <p>
            Upon initial setup in CrossCheck, you will be importing any
            historical student collection data collections that are no longer
            available on the collection requests tab of the data collector from
            the past 3 fiscal years. This only needs done one time. For example,
            FY20S data is not still available but is necessary for the 3-year
            lookback special education files:
          </p>
          <ol>
            <li>Log into the Data Collector</li>
            <li>
              Locate the collection you want to generate a file for in the
              archives tab.
            </li>
            <li>
              The files you import must be from the archives tab. If no archive
              exists for the desired collection, create one.
            </li>
            <li>Download the last collection preview files .zip.</li>
            <li>
              From this page, click on the Browse button and find the downloaded
              zip files.
            </li>
            <li>Once the zip file is selected, click on Import.</li>
            <li>
              Repeat to import all the zip files generated from the Data
              Collector.
            </li>
          </ol>
          <h5 className="mt-4">Select file to upload</h5>
          <input
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            onChange={handleFileChange}
          />
          {file && (
            <Button
              className="mt-2"
              variant="success"
              onClick={uploadData}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faUpload} /> Upload Files
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
      <Col>
        <h5>Import History</h5>
        <div className="table-wrapper">
          <Table striped bordered hover size="sm">
            <thead className="thead-dark">
              <tr>
                <th>Fiscal Year</th>
                <th>Dataset</th>
                <th>Record Type</th>
                <th>Import User</th>
                <th style={{ width: "175px" }}>Import Date</th>
                <th className="text-right">Records</th>
                <th className="text-center">Success</th>
              </tr>
            </thead>
            <tbody>
              {logsData.map((log, index) => (
                <tr key={index}>
                  <td>{log.fiscal_year}</td>
                  <td>{log.dataset?.description}</td>
                  <td>
                    {log.dataset?.name}
                    {log.sub_type && `- ${log.sub_type}`}
                  </td>
                  <td>{`${log.user?.first_name} ${log.user?.last_name}`}</td>
                  <td>
                    {new Date(log.transaction_log_date).toLocaleDateString()}
                    <br />
                    {new Date(log.transaction_log_date).toLocaleTimeString()}
                  </td>
                  <td className="text-right">{log.records_inserted}</td>
                  <td className="text-center">
                    {log.success ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-success-${index}`}>
                            {log.message ?? "Success"}
                          </Tooltip>
                        }
                      >
                        <span>
                          <FontAwesomeIcon icon={faCheck} color="green" />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-failure-${index}`}>
                            {log.message ?? "Failed"}
                          </Tooltip>
                        }
                      >
                        <span>
                          <FontAwesomeIcon icon={faBug} color="red" />
                        </span>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {importLogError && (
          <div className="alert alert-danger">{importLogError}</div>
        )}
        </div>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ImportData;
