import React, { useState, useContext, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./ChangePassword.css";

function ChangePassword({ show, handleCloseOnly }) {
  const [, setCloseModal] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [, setOldPassword] = useState("");
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  // const [showOldPassword, setShowOldPassword] = useState(false);
  

  const { users } = useContext(SyncReportsContext);
  const { selectedDistrict } = useDistrict();
  const navigate = useNavigate();

  // const toggleOldPasswordVisibility = () => {
  //   setShowOldPassword(!showOldPassword);
  // };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  useEffect(() => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setError("");
  }, [show]);

  const handleClose = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setError("");
    setCloseModal(true);
    navigate("/logout");
  };

  const handleUpdate = async () => {
    // if (!oldPassword) {
    //   setError("Please enter your old password");
    //   return;
    // }

    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match");
      return;
    }

    if (!newPassword) {
      setError("New password cannot be empty");
      return;
    }

    const user = users[0]; // Get the first user
    const requestBody = {
      username: user.email,
      user_id: user.user_id,
      password: newPassword,
    };

    try {
      const response = await axios.post(
        `/cc/users/${selectedDistrict.irn}`,
        JSON.stringify(requestBody),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        handleClose(); // Close the modal
      }
    } catch (err) {
      setError(
        err.response?.data?.error || "An error occurred during password update"
      );
    }
  };

  return (
    <Modal show={show} onHide={handleCloseOnly}>
      <Modal.Header closeButton>
        <Modal.Title>Update Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <p>You will be logged out if your update is successful.</p>
        <p>Please log back in with your new password.</p>
        <form>
          {/* Old Password */}
          {/* <div className="form-groupCP">
            <label htmlFor="oldPassword">Old Password</label>
            <div className="password-input">
              <input
                type={showOldPassword ? "text" : "password"}
                className="form-control"
                id="oldPassword"
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <FontAwesomeIcon
                icon={showOldPassword ? faEyeSlash : faEye}
                onClick={toggleOldPasswordVisibility}
                className="password-icon"
              />
            </div>
          </div> */}
          {/* New Password */}
          <div className="form-groupCP">
            <label htmlFor="newPassword">New Password</label>
            <div className="password-input">
              <input
                type={showNewPassword ? "text" : "password"}
                className="form-control"
                id="newPassword"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <FontAwesomeIcon
                icon={showNewPassword ? faEyeSlash : faEye}
                onClick={toggleNewPasswordVisibility}
                className="password-icon"
              />
            </div>
          </div>
          {/* Confirm New Password */}
          <div className="form-groupCP">
            <label htmlFor="confirmNewPassword">Confirm New Password</label>
            <div className="password-input">
              <input
                type={showConfirmNewPassword ? "text" : "password"}
                className="form-control"
                id="confirmNewPassword"
                placeholder="Confirm new password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <FontAwesomeIcon
                icon={showConfirmNewPassword ? faEyeSlash : faEye}
                onClick={toggleConfirmNewPasswordVisibility}
                className="password-icon"
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleCloseOnly}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangePassword;
