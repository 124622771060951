// import React from "react";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import DOMPurify from "dompurify";

// const MoreInfo = ({ show, handleClose, moreInfoContent }) => {
//   // Function to sanitize and return safe HTML
//   const createMarkup = (htmlContent) => {
//     return { __html: DOMPurify.sanitize(htmlContent) };
//   };

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>More Information</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {/* Render HTML content safely */}
//         <div dangerouslySetInnerHTML={createMarkup(moreInfoContent)} />
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default MoreInfo;

import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DOMPurify from "dompurify";

const MoreInfo = ({ show, handleClose, moreInfoContent }) => {
  
  useEffect(() => {
    // Attach event listener to handle clicks on links
    const handleLinkClick = (e) => {
      const link = e.target.closest("a");
      if (link && link.href && !link.href.startsWith(window.location.origin)) {
        e.preventDefault();
        window.open(link.href, "_blank", "noopener,noreferrer");
      }
    };

    // Add event listener
    document.addEventListener("click", handleLinkClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleLinkClick);
    };
  }, []);

  // Function to sanitize and return safe HTML
  const createMarkup = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>More Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Render HTML content safely */}
        <div dangerouslySetInnerHTML={createMarkup(moreInfoContent)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoreInfo;

