import React, { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../context/AdminProvider";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItems from "./MenuItems";
import DashboardReports from "./DashboardReports";
import NonDashboardReports from "./NonDashboardReports";
import Notes from "./Notes";
import IconModal from "./IconModal";
import EditMenu from "./EditMenu";
import EditReleaseNotes from "./EditReleaseNotes";
import EditReportNonDashboard from "./EditReportNonDashboard";
import EditReportDashboard from "./EditReportDashboard";
import "./Admin.css";

const Admin = () => {
  const {
    menu,
    getData,
    saveMenu,
    deleteMenu,
    publishReport,
    saveOrEditReport,
    deleteReport,
  } = useContext(AdminContext);
  const [sortedMenu, setSortedMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [editMenu, setEditMenu] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [iconModalShow, setIconModalShow] = useState(false);
  const [viewReports, setViewReports] = useState(null);
  const [editReport, setEditReport] = useState(null);
  const [editChildReport, setEditChildReport] = useState(null);
  // eslint-disable-next-line
  const [reportTitle, setReportTitle] = useState("");

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const excludedMenuTitles = [
      "Import Data",
      "Refresh Reports",
      "Sign Off",
      "Toggle Expand",
      "User Management",
      "Welcome",
      "crosscheck",
    ];

    const filteredMenu = menu.filter(
      (item) => !excludedMenuTitles.includes(item.title)
    );

    const sorted = filteredMenu.sort((a, b) => a.sort_by - b.sort_by);
    setSortedMenu(sorted);
  }, [menu]);

  const handleSelectMenu = (menuItem) => {
    setSelectedMenu(menuItem); // Set the selected menu item
    console.log("Menu Item Selected:", menuItem); // Log the menu item
  };

  const handleEditMenu = (field, value) => {
    setSelectedMenu({ ...selectedMenu, [field]: value });
  };

  const handleSaveMenu = () => {
    if (!selectedMenu.menu_type) {
      alert("Please select a menu type.");
      return;
    }
    saveMenu(selectedMenu);
    setEditMenu(false);
    setSelectedMenu(null);
  };

  const handleDeleteMenu = (menuItem) => {
    if (confirmDelete) {
      deleteMenu(menuItem);
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
  };

  const displayIcon = (iconName) => {
    return <FontAwesomeIcon icon={iconName || "question"} />;
  };

  const handleIconSelect = (icon) => {
    if (editReport) {
      setEditReport({ ...editReport, icon });
    }
    if (selectedMenu) {
      setSelectedMenu({ ...selectedMenu, icon_active: icon });
    }
    setIconModalShow(false);
  };

  const handleTogglePublish = async (report, publish) => {
    try {
      await publishReport(report, publish);
      setViewReports((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          reports: prev.reports.map((rep) =>
            rep.report_id === report.report_id
              ? { ...rep, publish: !rep.publish }
              : rep
          ),
        };
      });
    } catch (error) {
      console.error("Error toggling publish:", error);
    }
  };

  const handleViewReports = (menuItem) => {
    setSelectedMenu(menuItem); // Set the selected menu item for viewing reports
    setViewReports(menuItem); // Open the view reports screen
    setEditReport(null); // Ensure the edit screen is not open
  };

  const handleEditReport = (report) => {
    setEditReport(report);
  };

  const handleBackToMenu = () => {
    setViewReports(null);
    setEditReport(null);
    setEditChildReport(null);
  };

  const handleEditChildReport = (childReport) => {
    setEditChildReport(childReport);
  };

  const handleDeleteReport = async (report) => {
    if (window.confirm("Are you sure you want to delete this report?")) {
      await deleteReport(report); // Wait for the deletion to complete
      handleBackToMenu(); // Return to the main menu after deletion
    }
  };

  // handle release note report_title
  const handleReportTitle = (title) => {
    setReportTitle(title);
  };

  return (
    <div className="admin-container">
      <div className="header-container">
        <h2>CrossCheck Admin Dashboard</h2>
        {!editMenu && !editReport && !editChildReport && (
          <Button
            variant="success"
            onClick={() => {
              if (viewReports && selectedMenu) {
                setEditReport({ menu_id: selectedMenu.menu_id });
                console.log("Selected Menu ID:", selectedMenu.menu_id); // Log the menu_id here
              } else if (!viewReports) {
                setSelectedMenu({});
                setEditMenu(true); // Creating a new menuItem
              } else {
                alert("Please select a menu item first.");
              }
            }}
          >
            <FontAwesomeIcon icon="plus" title="Add New Item" />
          </Button>
        )}
      </div>

      {!editMenu && !viewReports && !editReport && !editChildReport && (
        <MenuItems
          sortedMenu={sortedMenu}
          handleSelectMenu={handleSelectMenu}
          handleViewReports={handleViewReports}
          handleDeleteMenu={handleDeleteMenu}
          confirmDelete={confirmDelete}
          handleCancelDelete={handleCancelDelete}
          displayIcon={displayIcon}
          setEditMenu={setEditMenu}
        />
      )}

      {!editMenu &&
        viewReports &&
        !editReport &&
        !editChildReport &&
        viewReports.menu_type === "notes" && (
          <Notes
            viewReports={viewReports}
            handleBackToMenu={handleBackToMenu}
            handleEditReport={handleEditReport} // Passing the edit function
            deleteReport={deleteReport} // Passing the delete function
            handleTogglePublish={handleTogglePublish}
          />
        )}

      {!editMenu &&
        viewReports &&
        !editReport &&
        !editChildReport &&
        viewReports.menu_type !== "dashboard" &&
        viewReports.menu_type !== "notes" && (
          <NonDashboardReports
            viewReports={viewReports}
            handleBackToMenu={handleBackToMenu}
            handleEditReport={handleEditReport}
            handleTogglePublish={handleTogglePublish}
            displayIcon={displayIcon}
            handleDeleteReport={handleDeleteReport}
          />
        )}

      {!editMenu &&
        viewReports &&
        !editReport &&
        !editChildReport &&
        viewReports.menu_type === "dashboard" && (
          <DashboardReports
            viewReports={viewReports}
            handleBackToMenu={handleBackToMenu}
            handleEditReport={handleEditReport}
            handleTogglePublish={handleTogglePublish}
            handleEditChildReport={handleEditChildReport}
            handleDeleteReport={handleDeleteReport}
          />
        )}

      {editMenu && (
        <EditMenu
          selectedMenu={selectedMenu}
          handleEditMenu={handleEditMenu}
          handleSaveMenu={handleSaveMenu}
          setEditMenu={setEditMenu}
          setSelectedMenu={setSelectedMenu}
          setIconModalShow={setIconModalShow}
        />
      )}

      {editReport &&
        viewReports &&
        viewReports.menu_type !== "dashboard" &&
        viewReports.menu_type !== "notes" && (
          <EditReportNonDashboard
            editReport={editReport}
            setEditReport={setEditReport}
            setIconModalShow={setIconModalShow}
            handleBackToMenu={handleBackToMenu}
            saveOrEditReport={saveOrEditReport}
            selectedMenu={selectedMenu}
          />
        )}

      {editReport && viewReports && viewReports.menu_type === "notes" && (
        <EditReleaseNotes
          editReport={editReport}
          setEditReport={setEditReport}
          handleBackToMenu={handleBackToMenu}
          saveOrEditReport={saveOrEditReport}
          selectedMenu={selectedMenu}
          // pass down the report_title
          handleReportTitle={handleReportTitle}
        />
      )}

      {editReport && viewReports && viewReports.menu_type === "dashboard" && (
        <EditReportDashboard
          editReport={editReport}
          setEditReport={setEditReport}
          handleBackToMenu={handleBackToMenu}
          saveOrEditReport={saveOrEditReport}
          selectedMenu={selectedMenu}
        />
      )}

      {editChildReport && (
        <EditReportDashboard
          editReport={editChildReport}
          setEditReport={setEditChildReport}
          handleBackToMenu={handleBackToMenu}
          saveOrEditReport={saveOrEditReport}
        />
      )}

      <IconModal
        show={iconModalShow}
        onHide={() => setIconModalShow(false)}
        onSelect={handleIconSelect}
      />
    </div>
  );
};

export default Admin;
