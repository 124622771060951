import React, { useState, useContext } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColumnModal from "./ColumnModal";
import { AdminContext } from "../../context/AdminProvider";
import "./Admin.css";

const EditReportNonDashboard = ({
  editReport,
  setEditReport,
  setIconModalShow,
  handleBackToMenu,
  selectedMenu,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState({});
  const { saveOrEditReport, saveColumn, getData } = useContext(AdminContext);

  const handleSaveReport = async () => {
    console.log("Selected Menu at the time of saving:", selectedMenu);

    if (!editReport.menu_id && selectedMenu?.menu_id) {
      editReport.menu_id = selectedMenu.menu_id;
      console.log("Assigned Selected Menu ID to report:", selectedMenu.menu_id);
    } else if (!editReport.menu_id) {
      console.error(
        "Menu ID is not set, and selectedMenu is null:",
        selectedMenu
      );
      alert("Please select a menu before saving the report.");
      return; // Prevent save if menu_id is not set
    }

    console.log("Final Report Object to be saved:", editReport);

    await saveOrEditReport(editReport);
    await getData(selectedMenu?.menu_id);
    handleBackToMenu();
  };

  const handleOpenModal = (column) => {
    setSelectedColumn(column || {});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedColumn({});
  };

  // Fields are disabled if the report is not published
  const isDisabled = editReport?.publish === 1;

  return (
    <div className="edit-menu-container">
      <Form>
        <div className="form-row">
          <div className="col-md-4 mb-2">
            <div className="editMenuTitle">
              <Form.Label>Menu Title</Form.Label>
              <Form.Control
                name="title"
                type="text"
                value={editReport.menu_title || ""}
                onChange={(e) =>
                  setEditReport({
                    ...editReport,
                    menu_title: e.target.value,
                  })
                }
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <Form.Label>Report Title</Form.Label>
            <Form.Control
              type="text"
              value={editReport.title || ""}
              onChange={(e) =>
                setEditReport({ ...editReport, title: e.target.value })
              }
              disabled={isDisabled}
            />
          </div>
          <div className="editIcon">
            <Form.Label>Icon</Form.Label>
            <br />
            <Button
              variant="outline-info"
              onClick={() => setIconModalShow(true)}
              disabled={isDisabled}
            >
              <FontAwesomeIcon
                icon={
                  editReport.icon ? `fas fa-${editReport.icon}` : "question"
                }
              />
            </Button>
          </div>
        </div>
        <div className="form-group">
          <Form.Label>More Info</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            className="edit_more_info"
            value={editReport.more_info || ""}
            onChange={(e) =>
              setEditReport({ ...editReport, more_info: e.target.value })
            }
            disabled={isDisabled}
          />
        </div>
        <div className="form-group">
          <Button
            variant="success"
            size="sm"
            className="float-right"
            onClick={() => handleOpenModal({})}
            disabled={isDisabled}
          >
            <FontAwesomeIcon icon="plus" />
          </Button>
          <Form.Label>Columns</Form.Label>
          <Table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Badge/File</th>
                <th>Title</th>
                <th className="center">Primary Key</th>
                <th className="center">Export Only</th>
                <th>Data Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {editReport.columns?.map((column, index) => (
                <tr key={index} draggable="true">
                  <td>{column.sort_by}</td>
                  <td>
                    <span className="badge badge-secondary">
                      {column.badge}
                    </span>
                  </td>
                  <td style={{ width: "40%" }}>{column.title}</td>
                  <td className="center">
                    {column.is_pk === 1 && <span>&#x2713;</span>}
                  </td>
                  <td className="center">
                    {column.export_only === 1 && <span>&#x2713;</span>}
                  </td>
                  <td>{column.data_type}</td>
                  <td className="nowrap">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleOpenModal(column)}
                      title="Edit Column"
                      disabled={isDisabled}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </Button>
                    {!column.confirmColDelete ? (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          /* logic to confirm delete column */
                        }}
                        title="Delete Column"
                        disabled={isDisabled}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            /* logic to cancel delete column */
                          }}
                          title="Cancel Delete"
                          disabled={isDisabled}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            /* logic to delete column */
                          }}
                          title="Confirm Delete"
                          disabled={isDisabled}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="form-group">
          <Form.Label>Report Query</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            className="code"
            value={editReport.sql || ""}
            disabled={isDisabled}
            onChange={(e) =>
              setEditReport({ ...editReport, sql: e.target.value })
            }
          />
          {/* The logic for this will come last */}
          <Button variant="outline-info" className="mt-2" disabled>
            Send Query to Editor
          </Button>
        </div>
        <div className="footer">
          <Button variant="secondary" onClick={handleBackToMenu}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveReport} disabled={isDisabled}>
            Save
          </Button>
        </div>
      </Form>
      <ColumnModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedColumn={selectedColumn}
        saveColumn={saveColumn}
      />
    </div>
  );
};

export default EditReportNonDashboard;
