import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./help.css";

const Introduction = () => {
  return (
    <Container className="help-container">
      <Row className="justify-content-md-center">
        <Col lg={12}>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h1 className="help-title">Introduction</h1>
              <Row className="align-items-center">
                <Col md={12}>
                  <p className="help-paragraph">
                    CrossCheck is an EMIS analytic application used by districts
                    to verify data, to find potential data issues, and to
                    simplify the process of analyzing EMIS data. CrossCheck
                    takes the most recent prepare or submission of EMIS data
                    from the data collector and runs a series of checks on the
                    data. Elements that meet rules for the checks will appear in
                    each area for the district to review. Access to the
                    application is restricted to authorized district and ITC
                    personnel. More information about users and access can be
                    found in the User Management page. The information provided
                    here pertains to the functions within CrossCheck. For
                    information pertaining to EMIS reporting or EMIS record
                    types, please reach out to your ITC. The CrossCheck
                    application runs on most standard browsers such as Chrome,
                    Firefox, Edge, and Safari.
                  </p>
                </Col>
                <Col md={7}>
                  {/* Placeholder for future images or additional content */}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Row className="align-items-center">
                <Col md={12}>
                  <h2 className="help-subtitle">Access information</h2>
                  <p className="help-paragraph">
                    Users are assigned access to CrossCheck through two ways:
                  </p>
                  <ol className="help-list">
                    <li className="help-list-item">
                      Active Directory users who have submitter or reviewer data
                      collector roles for a district automatically have full
                      access to CrossCheck. The username and password to log in
                      is the same as logging in to the data collector.
                    </li>
                    <li className="help-list-item">
                      Users can also be assigned access by active directory
                      users with submitter data collector role for a district.
                      Locally created users log in with their email and password
                      set by the user themselves. Passwords can be reset by the
                      active directory user with the submitter data collector
                      role for the district.
                    </li>
                  </ol>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Introduction;