export const adminRoles = [
  "ccadmin",
  "crosscheck_admin",
  "dataclctr_emisuser",
  "emis_r_itc_sif_administrator",
  "emisr_095895_itcsifadministrator",
  "healv_users",
  "itc_admin",
  "itcadmin",
  "itcsifadministrator",
  "itcsifadministrators",
  "itcsystemadministrator",
  "itcsystemsadministrator",
  "itcsystemsadministrators",
  "itcuser",
  "ne_emisr_sifadmin",
  "sif_admin",
  "sifitcsystemadministrator",
  "swoca_crosscheck",
  "woco_sifadmin",
  "emis-r itc user",
  "emis-r itc sif administrator",
  "dataclctr.emisuser",
  "ccadmin",
  "crosscheck_admin",
  "dataclctr_emisuser",
  "emis_r_itc_sif_administrator",
  "emisr_095895_itcsifadministrator",
];

export const checkRole = (roles, targetRoles) =>
  roles.some((role) =>
    targetRoles.some((target) => role.includes(target.toLowerCase()))
  );
