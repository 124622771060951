import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import "./help.css";
import um1 from "./HelpImgs/um1.2.png";
import um2 from "./HelpImgs/um2.png";
import um3 from "./HelpImgs/um3.png";
import um4 from "./HelpImgs/um4.png";
import um5 from "./HelpImgs/um5.png";
import um6 from "./HelpImgs/um6.png";
import um7 from "./HelpImgs/um7.png";
import um9 from "./HelpImgs/um9.png";
import um10 from "./HelpImgs/um10.png";
import um11 from "./HelpImgs/um11.png";
import um12 from "./HelpImgs/um12.png";
import um13 from "./HelpImgs/um13.png";

const UserManagement = () => {
  return (
    <Container className="help-container">
      <Row>
        <Col>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h1 className="help-title">User Management</h1>
              <p>
                A <strong>Locally Created User</strong> is added directly to
                CrossCheck via User Management. AD Users can access CrossCheck &
                the Data Collector. Authorization for AD Users comes from Active
                Directory at the ITC. AD Users with Submitter access to the Data
                Collector will have access to add, delete, update, and export
                users. Locally Created Users will only be able to change their
                password. ITC staff with SifITCAdmin access via AD can view,
                edit, and add add, delete, update, and export the local users to
                assist districts as needed.
              </p>
              <p>
                Exporting the user management page will produce a report that
                includes the staff member's demographic data and last login
                date.
              </p>
              <p>
                The user management screen shows any AD user that has logged in
                and any Locally Created User that exists. The screen does not
                show ITC support staff with ITCSifAdmin access.
              </p>
              <Image
                src={um1}
                alt="User Management Screen"
                fluid
                className="mb-4"
              />
            </Card.Body>
          </Card>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <Image
                src={um2}
                alt="User Management Screen"
                fluid
                className="mb-4"
                style={{ maxWidth: "40%", alignSelf: "center" }}
              />
              <p>
                The User Management Screen includes the ability to toggle user
                access columns. This is open by default, displaying the access
                columns. Once toggled, the table includes the user’s name, last
                login date, and the remove AD User column.
              </p>
              <Image
                src={um11}
                alt="User Management Screen"
                fluid
                className="mb-4"
                style={{ maxWidth: "100%", alignSelf: "center" }}
              />
              <p>
                Active Directory users are listed but have no hyperlink – these
                cannot be changed in CrossCheck. Active Directory users have
                full access to CrossCheck. The trash can on the right of the
                table is the option to Remove and AD User. AD Submitter and ITC
                Sif Admin users can use this feature. This action will remove
                the user from the CrossCheck system but will not affect their
                ITC Active Directory account. This should only be completed once
                their Active Directory access has been updated through the ITC.
                If a user logs back in after being removed, they will reappear
                in the table.
              </p>
              <p>
                Local Users in blue have been added to CrossCheck and can be
                edited within the application by AD users with Submitter or ITC
                access. Local users can change their password.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h2>Steps to Add a New User:</h2>
              {/* line break */}
              <hr />
              <Row>
                <Col xs={12} md={6}>
                  <Image src={um3} alt="Add New User" fluid />
                </Col>
                <Col xs={12} md={6}>
                  <Image src={um4} alt="Add Access" fluid />
                </Col>
              </Row>
              <hr />
              <ol>
                <li>Enter first name</li>
                <li>Enter last name</li>
                <li>Enter email address (this will be their username)</li>
                <li>Enter password</li>
                <li>Click to add access</li>
              </ol>
              <hr />
              {/* insert um12 */}
              <Image
                src={um12}
                alt="Add Access"
                fluid
                className="mb-4"
                style={{ maxWidth: "60%", alignSelf: "center" }}
              />

              <p className="mt-4">
                Select items the user will see. Click on the top-level name,
                e.g., district/building dashboard, to select all within the
                category. Click on a specific row, e.g., district SPED overview
                by disability, to select just that row. For Dashboard – the
                download box is selected by default; if you want to disable
                download, uncheck the download box. Be sure to scroll down to
                see all areas. Click the Update button to complete.{" "}
                <span style={{ color: "red" }}>
                  Please note – if a report did not exist when a user was
                  created, you will need to update access as desired to add new
                  reports as they are added.
                </span>
              </p>
              <Image
                src={um5}
                alt="Add Access"
                fluid
                className="mb-4"
                style={{ maxWidth: "30%", alignSelf: "center" }}
              />
              <p>
                AD Users can delete local users and update account information,
                including passwords. Update information and choose update. To
                delete a user, choose delete, then confirm.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h2>Locally Added User's View</h2>
              <hr />
              <Row>
                <Col md={6}>
                  <Image
                    src={um6}
                    alt="User Dashboard"
                    fluid
                    style={{ maxHeight: "80%" }}
                  />
                </Col>
                <Col md={6}>
                  <ul>
                    <li>
                      Only areas where access has been granted show for each
                      local user.
                    </li>
                    <li>
                      The user can use the User Management screen to change
                      their password or click on the icon below their name.
                    </li>
                    <li>
                      Local users will be prompted to change their password the
                      first time they log in. Passwords will expire every 90
                      days, the user will be prompted to reset it.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="help-card mb-4">
            <Card.Body className="help-card-body">
              <h2>Change or Update Password</h2>
              <hr />
              {/* insert UM13 */}
              <p>
                Local users will be prompted to change their password the first
                time they log in. Passwords will expire every 90 days, and the
                user will be prompted to reset it.
              </p>
              <Image
                src={um13}
                alt="Change Password"
                fluid
                className="mb-4"
                style={{ maxWidth: "40%", alignSelf: "center" }}
              />
              <p>
                Local users can change their password at any time. They can
                either click on User Management from the menu or click on the
                lock icon below their name.
              </p>
              <Image
                src={um9}
                alt="Change Password"
                fluid
                className="mb-4"
                style={{ maxWidth: "60%", alignSelf: "center" }}
              />
              <Image
                src={um7}
                alt="Change Password"
                fluid
                className="mb-4"
                style={{ maxWidth: "40%", alignSelf: "center" }}
              />
              <p>
                Enter the preferred password and update. First, Last, and
                Username fields are not editable by the user.
              </p>
              <Image
                src={um10}
                alt="Change Password"
                fluid
                style={{ maxWidth: "50%", alignSelf: "center" }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserManagement;
