import React from "react";
import { Alert } from "react-bootstrap";
import "./AlertHeading.css";

const AlertHeading = () => {
  return (
    <div className="alertContainer">
      <Alert variant="danger" className="alertMessage">
        Currently displaying data for the <strong>FY25</strong> school year.
      </Alert>
    </div>
  );
};

export default AlertHeading;
