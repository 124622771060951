import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { BlurContext } from "../../context/BlurContext";

export const DistrictContext = createContext();

export function useDistrict() {
  const context = useContext(DistrictContext);
  if (!context) {
    throw new Error("useDistrict must be used within a DistrictProvider");
  }
  return context;
}

export function DistrictProvider({ children }) {
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [admin, setAdmin] = useState(false);

  return (
    <DistrictContext.Provider
      value={{ selectedDistrict, setSelectedDistrict, admin, setAdmin }}
    >
      {children}
    </DistrictContext.Provider>
  );
}

function Breadcrumbs() {
  const { selectedDistrict, setSelectedDistrict, setAdmin } = useDistrict();
  const [data, setData] = useState({
    selected: { org: null, dc: null, dist: null },
    connections: { orgs: [] },
  });
  const { isBlurred } = useContext(BlurContext);
  const blurClass = isBlurred ? "blur" : "";

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/cc/access");
        const responseData = response.data;

        setData((prevData) => ({
          ...prevData,
          connections: responseData,
        }));

        if (responseData.success && responseData.orgs.length > 0) {
          const isAdmin = responseData.orgs[0]?.itc_admin ?? false;
          setAdmin(isAdmin);

          if (!isAdmin) {
            const savedData = JSON.parse(localStorage.getItem("breadcrumbs"));
            if (savedData) {
              setData((prevData) => ({
                ...prevData,
                selected: savedData,
              }));
              if (savedData.dist) {
                setSelectedDistrict(savedData.dist);
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [setSelectedDistrict, setAdmin]);

  const saveToLocalStorage = (selectedData) => {
    localStorage.setItem("breadcrumbs", JSON.stringify(selectedData));
    if (selectedData.org) localStorage.setItem("org", selectedData.org.id);
    if (selectedData.dc) localStorage.setItem("dc", selectedData.dc.id);
    if (selectedData.dist) localStorage.setItem("dist", selectedData.dist.id);
  };

  const setSelectedData = (selected) => {
    const updatedData = { ...data, selected };
    setData(updatedData);
    saveToLocalStorage(updatedData.selected);
  };

  const handleSelect = (item, key) => {
    setSelectedData({ ...data.selected, [key]: item });
    if (key === "dist") {
      setSelectedDistrict(item);
      navigate("/");
    }
  };

  const sortedOrgs = useMemo(
    () => data.connections.orgs.sort((a, b) => a.name.localeCompare(b.name)),
    [data.connections.orgs]
  );

  return (
    <div className="header-container">
      <Navbar expand="lg" className="header-navbar">
        <Container id="dropContainer">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown
                title={data.selected.org?.name ?? "Select Organization"}
                id="org-dropdown"
              >
                {sortedOrgs.map((org) => (
                  <NavDropdown.Item
                    key={org.id}
                    onClick={() => handleSelect(org, "org")}
                  >
                    {org.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>

              {data.selected.org && (
                <NavDropdown
                  title={data.selected.dc?.name ?? "Select DataCollector"}
                  id="data-collector-dropdown"
                >
                  {data.selected.org.datacollectors.map((dc) => (
                    <NavDropdown.Item
                      key={dc.id}
                      onClick={() => handleSelect(dc, "dc")}
                    >
                      {dc.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              )}

              {data.selected.dc && (
                <NavDropdown
                  title={data.selected.dist?.name ?? "Select District"}
                  id="district-dropdown"
                  className={blurClass} // Apply blur class here
                >
                  {data.selected.dc.districts
                    .filter((district) => district.status !== "Register")
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((dist) => (
                      <NavDropdown.Item
                        key={dist.id}
                        onClick={() => handleSelect(dist, "dist")}
                        className={
                          dist.id === selectedDistrict?.id ? blurClass : ""
                        } // Apply blur to selected district
                      >
                        {dist.name}
                      </NavDropdown.Item>
                    ))}
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Breadcrumbs;
