import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { useDistrict } from "../../components/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { Card, ProgressBar, Table, Modal, Button } from "react-bootstrap";
import "./Load.css";
import AlertHeading from "../AlertHeading/AlertHeading";

function Load() {
  const { syncStatus, records, reportData, crosscheckAdmin, dataSources } =
    useContext(SyncReportsContext);
  const { selectedDistrict } = useDistrict();
  const navigate = useNavigate();

  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [, setRemainingReports] = useState(0);
  const [isSyncModalVisible, setIsSyncModalVisible] = useState(false);
  const [, setTotalReports] = useState(0);
  const [isModalClosedManually, setIsModalClosedManually] = useState(false);
  const [isAdminTableVisible, setIsAdminTableVisible] = useState(false);

  // Effect to update modal visibility and completion percentage
  useEffect(() => {
    if (selectedDistrict) {
      let loadingCount = 0;
      const reportCount = Object.values(reportData).length;

      Object.values(reportData).forEach((report) => {
        if (report.loading) {
          loadingCount++;
        }
      });

      setRemainingReports(loadingCount);
      setTotalReports(reportCount);

      if (!isModalClosedManually) {
        setIsSyncModalVisible(loadingCount > 0);
      }

      const completed = reportCount - loadingCount;
      const percentage = (completed / reportCount) * 100;
      setPercentageCompleted(Math.round(percentage));

      if (loadingCount === 0) {
        setIsModalClosedManually(false); // Reset manual close flag
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, selectedDistrict]);

  // Effect to handle when the modal is closed manually
  useEffect(() => {
    if (!isSyncModalVisible) {
      setIsModalClosedManually(true);
    }
  }, [isSyncModalVisible]);

  const handleCloseSyncModal = useCallback(() => {
    setIsSyncModalVisible(false);
  }, []);

  const renderSyncStatus = () => {
    const messageMap = {
      syncing: (
        <div className="info-message">
          <h3 className="syncTitle">Synchronization in Progress</h3>
          <p className="disclaimer">
            The Data Collector is currently syncing. The Reports menu will
            become available once synchronization is complete.
          </p>
          <ProgressBar animated now={100} />
        </div>
      ),
      success: (
        <div className="success-message">
          <FontAwesomeIcon icon={faCheckCircle} className="icon-success" />
          <p className="syncSuccessMessage">
            Crosscheck is up to date with the Data Collector.
          </p>
          <AlertHeading className="loadAlert" />
        </div>
      ),
      error: (
        <div className="error-message">
          <FontAwesomeIcon icon={faTimesCircle} className="icon-error" />
          <p className="syncErrorMessage">
            Synchronization error - please retry or contact support.
          </p>
        </div>
      ),
    };
    return messageMap[syncStatus] || null;
  };

  const renderTableRows = () =>
    records.flatMap((collection, collectionIndex) =>
      collection.tables.map((table, tableIndex) => (
        <tr
          key={`${collectionIndex}-${tableIndex}`}
          className={table.success ? "alert-success" : "alert-danger"}
        >
          <td>{collection.collection_name}</td>
          <td>{collection.fiscal_year}</td>
          <td>{table.table}</td>
          <td>{table.message}</td>
        </tr>
      ))
    );

  const renderAdminTableRows = () => {
    return Object.entries(dataSources).length > 0 ? (
      Object.entries(dataSources).map(([key, src]) => (
        <tr key={key}>
          <td>{key}</td>
          <td>{src.collection_type}</td>
          <td>{src.description}</td>
          <td>{src.table_name}</td>
          <td>
            {src.collection_date
              ? new Date(src.collection_date).toLocaleDateString()
              : "N/A"}
          </td>
          <td>
            {src.last_loaded
              ? new Date(src.last_loaded).toLocaleDateString()
              : "N/A"}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="6" className="text-center">
          No data sources available.
        </td>
      </tr>
    );
  };

  const toggleAdminTableVisibility = () => {
    setIsAdminTableVisible(!isAdminTableVisible);
  };

  return (
    <Card className="load-container text-center">
      {!selectedDistrict && (
        <div className="welcome-screen">
          <img
            src={require("./header_logo.png")}
            alt="Header Logo"
            className="welcome-screen-logo"
          />
          <div className="welcome-screen-title">
            <h3>Welcome to CrossCheck</h3>
            <p>Please select a district to begin</p>
          </div>
          <AlertHeading className="loadAlert" />
        </div>
      )}
      {selectedDistrict && renderSyncStatus()}
      {selectedDistrict && records.length > 0 && (
        <Table striped bordered hover size="lg" className="bg-white">
          <thead>
            <tr>
              <th>Collection</th>
              <th>Fiscal Year</th>
              <th>Table</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </Table>
      )}
      {selectedDistrict && crosscheckAdmin && (
        <div className="admin-table-container">
          <h4
            onClick={toggleAdminTableVisibility}
            style={{ cursor: "pointer" }}
          >
            Admin Table{" "}
            <FontAwesomeIcon
              icon={isAdminTableVisible ? faChevronUp : faChevronDown}
            />
          </h4>
          {isAdminTableVisible && (
            <Table striped bordered hover size="sm">
              <thead
                className="thead-dark"
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "1000",
                  backgroundColor: "white",
                }}
              >
                <tr>
                  <th>Record Type</th>
                  <th>Collection Type</th>
                  <th>Description</th>
                  <th>Table Name</th>
                  <th>Date Collected</th>
                  <th>Imported into CC</th>
                </tr>
              </thead>
              <tbody>{renderAdminTableRows()}</tbody>
            </Table>
          )}
        </div>
      )}
      {selectedDistrict && (
        <Modal
          show={isSyncModalVisible}
          onHide={handleCloseSyncModal}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Synchronization in Progress</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Your reports are updating. Please wait while we finalize the
              updates. The navigation menu will automatically refresh with the
              latest data once synchronization is complete.
            </p>
            <p>
              <strong>
                If you close this modal before the sync finishes, the process
                will continue in the background.
              </strong>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100">
              <ProgressBar
                animated
                now={percentageCompleted}
                label={`${percentageCompleted}%`}
              />
            </div>
            <AlertHeading className="loadAlert" />
            <Button variant="secondary" onClick={handleCloseSyncModal}>
              Close
            </Button>
            <Button variant="primary" onClick={() => navigate("/logout")}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Card>
  );
}

export default Load;
