import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faToolbox,
  faUserNinja,
  faLock,
  faHome,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "../ChangePassword/ChangePassword";
import { BlurContext } from "../../context/BlurContext";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import "./Links.css";
import DownloadReportDropdown from "../DownloadReportDropdown/DownloadReportDropdown";
import HelpLink from "./HelpLink";

const Links = () => {
  const [username, setUsername] = useState("");
  const { isBlurred, setIsBlurred } = useContext(BlurContext);
  const { userAd, crosscheckAdmin } = useContext(SyncReportsContext);
  const blurClass = isBlurred ? "blur" : "";

  useEffect(() => {
    const savedDisplayName = localStorage.getItem("display_name");
    if (savedDisplayName) {
      setUsername(savedDisplayName);
    }
  }, []);

  const toggleBlur = () => setIsBlurred(!isBlurred);

  return (
    <div className="header-container right-header">
      <div className="welcome-message">
        Welcome, <span className={blurClass}>{username}</span>
      </div>
      <div className="links">
        <HomeLink />
        <button onClick={toggleBlur} className="blur-toggle" title="Blur Data">
          <FontAwesomeIcon icon={faUserNinja} />
        </button>
        {crosscheckAdmin && <AdminLink />}
        {!crosscheckAdmin && !userAd && <UpdatePasswordLink />}
        <DownloadReportDropdown />
        <HelpLink />
        {/* {userAd && <SubmitTicketLink />}  */}
        <LogoutLink />
      </div>
    </div>
  );
};

const AdminLink = () => (
  <a href="/admin" target="_blank" rel="noopener noreferrer" title="Admin Page">
    <FontAwesomeIcon icon={faToolbox} />
  </a>
);

const HomeLink = () => (
  <Link to="/" title="Welcome">
    <FontAwesomeIcon icon={faHome} />
  </Link>
);

const LogoutLink = () => (
  <Link to="logout" title="Sign Off">
    <FontAwesomeIcon icon={faArrowRightFromBracket} />
  </Link>
);

const UpdatePasswordLink = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FontAwesomeIcon
        icon={faLock}
        className="fa-lock"
        onClick={handleShow}
        title="Change Password"
      />
      <ChangePassword show={show} handleClose={handleClose} />
    </>
  );
};

const SubmitTicketLink = () => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevents the default navigation action
    window.open( "mailto:emsihelp@metasolutions.net?subject=Support Ticket&body=Please describe your issue or request.");
  }

  return (
    <a
      href="mailto:emsihelp@metasolutions.net"
      onClick={handleClick}
      title="Submit a Support Ticket"
    >
      <FontAwesomeIcon icon={faEnvelope} />
    </a>
  );
};


export default Links;
